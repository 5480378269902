import { inject } from '@angular/core';

import {
  patchState,
  signalStore,
  type,
  withMethods,
  withState
} from '@ngrx/signals';
import {
  entityConfig,
  removeAllEntities,
  withEntities
} from '@ngrx/signals/entities';

import { withDevtools } from 'src/app/shared/functions/redux-dev-tools';

import { MeetingsService } from '../meetings.service';
import { ReportingMeeting } from '../models/meetings.models';
import {
  cancelOffPlatformMeeting,
  exportMeetings,
  fetchReportingMeetings,
  getOffPlatformMeetingDetails,
  updateOffPlatformMeeting,
  uploadOffPlatform
} from './meetings.methods';

export interface MeetingsState {
  uploadOffPlatformInflight: boolean;
  fetchReportingMeetingsInflight: boolean;
  exportMeetingsInflight: boolean;
  getOffPlatformMeetingDetailsInflight: boolean;
  updateOffPlatformMeetingInflight: boolean;
  cancelOffPlatformMeetingInflight: boolean;
}

const defaultMeetingState: MeetingsState = {
  uploadOffPlatformInflight: false,
  fetchReportingMeetingsInflight: false,
  exportMeetingsInflight: false,
  getOffPlatformMeetingDetailsInflight: false,
  updateOffPlatformMeetingInflight: false,
  cancelOffPlatformMeetingInflight: false
};

export const meetingsCollection = entityConfig({
  entity: type<ReportingMeeting>(),
  selectId: (meeting) => meeting.meetingId,
  collection: 'meetings'
});

export const MeetingsStore = signalStore(
  { providedIn: 'root' },
  withDevtools('meetings'),
  withState<MeetingsState>(defaultMeetingState),
  withEntities(meetingsCollection),
  withMethods((store) => {
    const meetingsService = inject(MeetingsService);
    return {
      uploadOffPlatform: uploadOffPlatform(meetingsService, store),
      fetchReportingMeetings: fetchReportingMeetings(meetingsService, store),
      exportMeetings: exportMeetings(meetingsService, store),
      getOffPlatformMeetingDetails: getOffPlatformMeetingDetails(
        meetingsService,
        store
      ),
      updateOffPlatformMeeting: updateOffPlatformMeeting(
        meetingsService,
        store
      ),
      cancelOffPlatformMeeting: cancelOffPlatformMeeting(
        meetingsService,
        store
      ),
      reset: () => {
        patchState(store, defaultMeetingState);
        patchState(store, removeAllEntities(meetingsCollection));
      },
      destroy: () => patchState(store, defaultMeetingState)
    };
  })
);
