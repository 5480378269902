import { inject } from '@angular/core';
import { CanDeactivateFn } from '@angular/router';

import { AxleReportingContainerComponent } from 'src/app/features/reporting/container/reporting-container.component';

import { MeetingsStore } from '../services/meetings/state/meetings.state';

export const clearMeetingsStore: CanDeactivateFn<
  AxleReportingContainerComponent
> = () => {
  const meetingsStore = inject(MeetingsStore);
  meetingsStore.reset();
  return true;
};
