import { inject } from '@angular/core';
import { CanDeactivateFn } from '@angular/router';

import { AxleContactsContainerComponent } from 'src/app/features/contacts/reskin/container/axle-contacts-container.component';

import { AxleOrgContactsPaginatorService } from '../services/crm/paginator/crm.org.paginator.service';
import { AxleUserContactsPaginatorService } from '../services/crm/paginator/crm.user.paginator.service';
import { ContactsStore } from '../services/crm/state/crm.state';

export const clearContactsStore: CanDeactivateFn<
  AxleContactsContainerComponent
> = () => {
  const contactsStore = inject(ContactsStore);
  const userPaginatorService = inject(AxleUserContactsPaginatorService);
  const orgPaginatorService = inject(AxleOrgContactsPaginatorService);

  contactsStore.resetNewContacts();
  userPaginatorService.reset();
  orgPaginatorService.reset();

  return true;
};
