import {
  provideHttpClient,
  withInterceptors,
  withInterceptorsFromDi
} from '@angular/common/http';
import {
  ApplicationRef,
  ErrorHandler,
  inject,
  provideAppInitializer
} from '@angular/core';
import {
  bootstrapApplication,
  enableDebugTools
} from '@angular/platform-browser';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import {
  provideRouter,
  withComponentInputBinding,
  withViewTransitions
} from '@angular/router';

import {
  AuthHttpInterceptor,
  authHttpInterceptorFn,
  provideAuth0
} from '@auth0/auth0-angular';
import { provideTransloco } from '@ngneat/transloco';
import {
  browserTracingIntegration,
  createErrorHandler,
  init
} from '@sentry/angular';
import { provideCharts, withDefaultRegisterables } from 'ng2-charts';
import posthog from 'posthog-js';

import { AppComponent } from './app/app.component';
import { Action } from './app/shared/functions/redux-dev-tools';
import { whitelistInterceptorFn } from './app/shared/interceptors/shared-interceptors';
import { GoogleMapsLoaderService } from './app/shared/services/google-maps/google-maps-loader.service';
import { AxleToastService } from './app/shared/services/toast/axle-toast.service';
import { UserStore } from './app/shared/services/user/user.state';
import { TranslocoHttpLoader } from './app/transloco-loader';
import { appVersion } from './app/version';
import { environment } from './environments/environment';
import { routes } from './routes';

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    Intercom: any;
    __REDUX_DEVTOOLS_EXTENSION__:
      | {
          connect: (options: { name: string }) => {
            send: (action: Action, state: Record<string, unknown>) => void;
          };
        }
      | undefined;
  }
}

async function initializeGoogleMaps() {
  const googleMapsLoaderService = inject(GoogleMapsLoaderService);
  return googleMapsLoaderService.loadScript();
}

async function initializeIntercom(): Promise<void> {
  window.Intercom('boot', {
    api_base: 'https://api-iam.intercom.io',
    app_id: 'ixtfpdrt'
  });
}

async function initializePosthog(): Promise<void> {
  if (
    !window.location.host.includes('127.0.0.1') &&
    !window.location.host.includes('localhost')
  ) {
    posthog.init('phc_B44LvJW3JBsMdNAM5rjNBXg9iCml1SACjcMsKhU6ve0', {
      api_host: 'https://app.posthog.com',
      secure_cookie: true
    });
  }
}

async function initializeSentry(): Promise<void> {
  const toast = inject(AxleToastService);
  const userStore = inject(UserStore);
  await init({
    dsn: 'https://4ad21a6729c420b2d3cac55ebf45161c@o4506972498952192.ingest.us.sentry.io/4506972507996160',
    integrations: [browserTracingIntegration()],
    tracesSampleRate: 1.0,
    tracePropagationTargets: [
      'localhost',
      'https://127.0.0.1:8443',
      'api-dev.axleaccess.com',
      'axle-qa.herokuapp.com',
      'api.axleaccess.com'
    ],
    beforeSend: (event) => {
      event.tags = {
        ...event.tags,
        env: environment.env,
        version: appVersion,
        userId: userStore.userInfo()?.id
      };

      const errorMessage = event.exception?.values?.[0]?.value;
      if (
        errorMessage?.includes('Failed to fetch dynamically imported module')
      ) {
        toast.addError(
          'Something went wrong. Your version might be outdated, so please refresh and try again.'
        );
      } else {
        toast.addError(
          "Something went wrong. Please contact support in the bottom right if it doesn't resolve."
        );
      }
      return event;
    }
  });
}

export function getAuth0Config(): typeof environment.auth0 {
  const hostname = window.location.hostname;
  const subdomain = hostname.split('.')[0];

  const subdomainConfigs: Record<string, typeof environment.auth0> = {
    canaccord: {
      ...environment.auth0,
      authorizationParams: {
        redirect_uri: window.location.origin,
        connection: 'canaccord-genuity-sso'
      }
    }
  };

  return subdomainConfigs[subdomain] || environment.auth0;
}

bootstrapApplication(AppComponent, {
  providers: [
    provideHttpClient(withInterceptorsFromDi()),
    provideAnimationsAsync(),
    provideRouter(routes, withViewTransitions(), withComponentInputBinding()),
    provideTransloco({
      config: {
        availableLangs: [
          {
            id: 'en',
            label: 'English'
          },
          { id: 'es', label: 'Spanish' },
          { id: 'fr', label: 'French' }
        ],
        defaultLang: 'en',
        reRenderOnLangChange: true
        // prodMode: environment.production
      },
      loader: TranslocoHttpLoader
    }),
    provideAuth0(getAuth0Config()),
    AuthHttpInterceptor,
    provideHttpClient(
      withInterceptors([authHttpInterceptorFn, whitelistInterceptorFn])
    ),
    // provideExperimentalZonelessChangeDetection(),
    // provideExperimentalCheckNoChangesForDebug({
    //   interval: 1000, // run change detection every second
    //   useNgZoneOnStable: true, // run it when the NgZone is stable as well
    //   exhaustive: true // check all components
    // }),
    provideAppInitializer(() => initializeGoogleMaps()),
    provideAppInitializer(() => initializeSentry()),
    provideAppInitializer(() => initializeIntercom()),
    provideAppInitializer(() => initializePosthog()),
    {
      provide: ErrorHandler,
      useValue: createErrorHandler({
        showDialog: false,
        dialogOptions: {
          enabled: false
        },
        logErrors: true
      })
    },
    provideCharts(withDefaultRegisterables())
  ]
}).then((module) =>
  enableDebugTools(module.injector.get(ApplicationRef).components[0])
);
