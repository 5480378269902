import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';

import { AuthService } from '@auth0/auth0-angular';
import { filter, switchMap } from 'rxjs';

import { jsonDefaultRequestOptions } from 'src/app/shared/contants/http.constants';
import { handleHttpResponse } from 'src/app/shared/functions/http.functions';
import { AuthenticationStore } from 'src/app/shared/state/authentication/authentication.state';
import { environment } from 'src/environments/environment';

import {
  Campaign,
  GetCampaignDraftsPayload,
  GetCampaignDraftsResponse,
  GetCampaignOrgMetrics,
  GetCampaignOrgMetricsResponse,
  GetCampaignSentPayload,
  GetCampaignUserMetrics,
  GetCampaignUserMetricsResponse,
  GetSentCampaignMetricsPayload,
  GetSentCampaignMetricsResponse,
  GetSentCampaignsResponse,
  UpsertCampaign
} from './email.models';

const baseEmailCampaignUrl = `${environment.backendLink}/email/campaign`;
const getCampaignDraftsUrl = `${baseEmailCampaignUrl}/draft`;
const getSentCampaignsUrl = `${baseEmailCampaignUrl}/sent`;
const getSentCampaignMetricsUrl = `${baseEmailCampaignUrl}/sent/metrics`;
const sendCampaignUrl = `${baseEmailCampaignUrl}/send`;
const sendTestCampaignUrl = `${baseEmailCampaignUrl}/send/test`;
const getCampaignUserMetricsUrl = `${baseEmailCampaignUrl}/metrics/user`;
const getCampaignOrgMetricsUrl = `${baseEmailCampaignUrl}/metrics/org`;
const getCampaignDetailsUrl = (id: string) => `${baseEmailCampaignUrl}/${id}`;

@Injectable({
  providedIn: 'root'
})
export class EmailService {
  private _auth0Service = inject(AuthService);
  private _httpClient = inject(HttpClient);
  private _authStore = inject(AuthenticationStore);

  getCampaignDetails$(id: string) {
    return this._auth0Service.isAuthenticated$.pipe(
      filter((authenticated) => authenticated),
      switchMap(() => {
        return handleHttpResponse(
          this._httpClient.get<Campaign>(
            getCampaignDetailsUrl(id),
            jsonDefaultRequestOptions
          ),
          'getCampaignDrafts$',
          this._authStore.logout
        );
      })
    );
  }

  getCampaignDrafts$(
    size: number,
    page: number,
    payload: GetCampaignDraftsPayload
  ) {
    return this._auth0Service.isAuthenticated$.pipe(
      filter((authenticated) => authenticated),
      switchMap(() => {
        return handleHttpResponse(
          this._httpClient.post<GetCampaignDraftsResponse>(
            getCampaignDraftsUrl,
            JSON.stringify(payload),
            {
              ...jsonDefaultRequestOptions,
              params: { size, page }
            }
          ),
          'getCampaignDrafts$',
          this._authStore.logout
        );
      })
    );
  }

  getSentCampaigns$(
    size: number,
    page: number,
    payload: GetCampaignSentPayload
  ) {
    return this._auth0Service.isAuthenticated$.pipe(
      filter((authenticated) => authenticated),
      switchMap(() => {
        return handleHttpResponse(
          this._httpClient.post<GetSentCampaignsResponse>(
            getSentCampaignsUrl,
            JSON.stringify(payload),
            {
              ...jsonDefaultRequestOptions,
              params: { size, page }
            }
          ),
          'getSentCampaigns$',
          this._authStore.logout
        );
      })
    );
  }

  getSentCampaignMetrics$(payload: GetSentCampaignMetricsPayload) {
    return this._auth0Service.isAuthenticated$.pipe(
      filter((authenticated) => authenticated),
      switchMap(() =>
        handleHttpResponse(
          this._httpClient.post<GetSentCampaignMetricsResponse>(
            getSentCampaignMetricsUrl,
            JSON.stringify(payload),
            jsonDefaultRequestOptions
          ),
          'getSentCampaignMetrics$',
          this._authStore.logout
        )
      )
    );
  }

  upsertCampaign$(payload: UpsertCampaign) {
    return this._auth0Service.isAuthenticated$.pipe(
      filter((authenticated) => authenticated),
      switchMap(() =>
        handleHttpResponse(
          this._httpClient.put<Campaign>(
            baseEmailCampaignUrl,
            JSON.stringify(payload),
            jsonDefaultRequestOptions
          ),
          'upsertCampaign$',
          this._authStore.logout
        )
      )
    );
  }

  sendCampaign$(payload: UpsertCampaign) {
    return this._auth0Service.isAuthenticated$.pipe(
      filter((authenticated) => authenticated),
      switchMap(() =>
        handleHttpResponse(
          this._httpClient.post<Campaign>(
            sendCampaignUrl,
            payload,
            jsonDefaultRequestOptions
          ),
          'sendCampaign$',
          this._authStore.logout,
          30000
        )
      )
    );
  }

  sendTestCampaign$(payload: UpsertCampaign) {
    return this._auth0Service.isAuthenticated$.pipe(
      filter((authenticated) => authenticated),
      switchMap(() =>
        handleHttpResponse(
          this._httpClient.post<Campaign>(
            sendTestCampaignUrl,
            payload,
            jsonDefaultRequestOptions
          ),
          'sendTestCampaign$',
          this._authStore.logout
        )
      )
    );
  }

  campaignUserMetrics$(payload: GetCampaignUserMetrics) {
    return this._auth0Service.isAuthenticated$.pipe(
      filter((authenticated) => authenticated),
      switchMap(() =>
        handleHttpResponse(
          this._httpClient.post<GetCampaignUserMetricsResponse>(
            getCampaignUserMetricsUrl,
            JSON.stringify(payload),
            jsonDefaultRequestOptions
          ),
          'campaignUserMetrics$',
          this._authStore.logout
        )
      )
    );
  }

  campaignOrgMetrics$(payload: GetCampaignOrgMetrics) {
    return this._auth0Service.isAuthenticated$.pipe(
      filter((authenticated) => authenticated),
      switchMap(() =>
        handleHttpResponse(
          this._httpClient.post<GetCampaignOrgMetricsResponse>(
            getCampaignOrgMetricsUrl,
            JSON.stringify(payload),
            jsonDefaultRequestOptions
          ),
          'campaignOrgMetrics$',
          this._authStore.logout
        )
      )
    );
  }

  deleteCampaigns$(ids: string[]) {
    return this._auth0Service.isAuthenticated$.pipe(
      filter((authenticated) => authenticated),
      switchMap(() => {
        return handleHttpResponse(
          this._httpClient.delete(baseEmailCampaignUrl, {
            ...jsonDefaultRequestOptions,
            body: JSON.stringify(ids)
          }),
          'deleteCampaign$',
          this._authStore.logout
        );
      })
    );
  }
}
