import { Injectable, computed, inject } from '@angular/core';

import { patchState, signalState } from '@ngrx/signals';
import equal from 'fast-deep-equal/es6';

import {
  ActiveAudienceFilter,
  FilterGroup
} from 'src/app/shared/components/overlays/audience-filter-overlay/axle-audience-filter-overlay.component';
import { downloadFileUrl } from 'src/app/shared/functions/http.functions';
import { SortDirection } from 'src/app/shared/models/table.models';

import {
  AudienceFilterGroup,
  ConditionalType,
  GetUsersPayload,
  LightUser,
  SelectAudienceFilterCategory,
  UserContactSortField
} from '../models/crm.models';
import { ContactsStore } from '../state/crm.state';

interface UserContactsPaginatorState {
  page: number;
  totalPages: number;
  totalElements: number;
  hasNext: boolean;
  userIdsByPage: Record<number, Set<string>> | null;
  searchQuery: string;
  sortField: UserContactSortField;
  sortDirection: SortDirection;
  filterGroups: Record<string, AudienceFilterGroup>;
  filterGroupConditionalType: ConditionalType;
}

const defaultState: UserContactsPaginatorState = {
  page: 0,
  totalPages: 0,
  totalElements: 0,
  hasNext: true,
  userIdsByPage: null,
  searchQuery: '',
  sortField: 'name',
  sortDirection: 'ASC',
  filterGroups: {},
  filterGroupConditionalType: 'AND'
};

@Injectable({ providedIn: 'root' })
export class AxleUserContactsPaginatorService {
  private _contactsStore = inject(ContactsStore);

  state = signalState<UserContactsPaginatorState>(defaultState);

  inflight = this._contactsStore.getUsersInflight;

  initialLoad = computed(() => !this.state.userIdsByPage() && this.inflight());
  loaded = computed(() => this.state.userIdsByPage());

  filteredContacts = computed(() => {
    const userIds = this.state.userIdsByPage();
    const currentPage = this.state.page();
    const ids = userIds?.[currentPage] || new Set();

    if (!ids.size) return [];
    const contacts: LightUser[] = [];
    ids.forEach((userId) => {
      const user = this._contactsStore.strictNewUsersEntityMap()?.[userId];
      if (user) {
        contacts.push(user);
      }
    });

    return contacts;
  });

  pageInfo = computed(() => {
    const { page, totalPages, totalElements } = this.state();
    const windowSize = 5;

    const calculatePageRange = (
      current: number,
      total: number,
      size: number
    ) => {
      const half = Math.floor(size / 2);
      let start = Math.max(0, current - half);
      const end = Math.min(total, start + size) - 1;
      start = Math.max(0, end - size + 1);
      return { start, end };
    };

    const createPageArray = (start: number, end: number) =>
      Array.from({ length: end - start + 1 }, (_, i) => start + i);

    const { start, end } = calculatePageRange(page, totalPages, windowSize);
    const totalPagesArray = createPageArray(start, end);

    return { page, totalPagesArray, totalPages, totalElements };
  });

  itemsRange = computed(() => {
    const { page, totalElements } = this.state();
    const startItem = page * 200 + 1;
    const endItem = Math.min(startItem + 200 - 1, totalElements);

    return {
      startItem,
      endItem,
      totalElements
    };
  });

  updateSearch(searchQuery: string) {
    patchState(this.state, {
      searchQuery
    });
    this.getUsers('NEW');
  }

  changeSort(newField: UserContactSortField) {
    const { sortField, sortDirection } = this.state();
    if (sortField === newField) {
      patchState(this.state, {
        sortDirection: sortDirection === 'ASC' ? 'DESC' : 'ASC'
      });
    } else {
      patchState(this.state, {
        sortField: newField,
        sortDirection: 'ASC'
      });
    }
    this.getUsers('NEW');
  }

  selectPage(page: number) {
    const { page: currentPage, totalPages } = this.state();
    if (page === currentPage || page >= totalPages || page < 0) {
      return;
    }

    const userIdsByPage = this.state.userIdsByPage();
    const alreadyFetched = userIdsByPage && page in userIdsByPage;
    if (alreadyFetched) {
      patchState(this.state, { page });
      return;
    }

    this.getUsers('SCROLL', page);
  }

  updateFilterGroups(
    filterGroups: Record<string, FilterGroup>,
    filterGroupConditionalType: ConditionalType
  ) {
    const transformedGroups = Object.entries(filterGroups).reduce<
      Record<string, AudienceFilterGroup>
    >((acc, [id, group]) => {
      const activeFilters = Object.values(group.filters)
        .map((filter) => filter.filterForm.values())
        .filter((filter): filter is ActiveAudienceFilter => {
          if (!filter.category) return false;

          if (filter.category === 'LOCATION') {
            const { googlePlace, distance } = filter;
            return !!(googlePlace?.lat && googlePlace?.lng && distance);
          }

          return !!filter.values?.length;
        });

      if (!activeFilters.length) return acc;

      acc[id] = {
        andOr: group.conditionalType,
        audienceFilters: activeFilters.map(
          ({
            equalType,
            category,
            entityType,
            googlePlace,
            distance,
            values
          }) => {
            let _category: SelectAudienceFilterCategory;
            if (category === 'TAGS') {
              _category =
                entityType === 'USER' ? 'USER_TAGS' : 'ORGANIZATION_TAGS';
            } else if (category === 'LOCATION') {
              _category =
                entityType === 'USER' ? 'USER_LOCATIONS' : 'ORG_LOCATIONS';
            } else {
              _category = category;
            }

            return {
              category: _category,
              equalType,
              options: values ? [...values] : undefined,
              coordinates:
                category === 'LOCATION' &&
                googlePlace?.lat &&
                googlePlace.lng &&
                distance
                  ? {
                      lat: googlePlace.lat,
                      lng: googlePlace.lng,
                      formattedAddress: googlePlace.formattedAddress,
                      distanceRange: distance
                    }
                  : undefined
            };
          }
        )
      };

      return acc;
    }, {});

    const existingGroups = this.state.filterGroups();
    const existingConditionalType = this.state.filterGroupConditionalType();

    if (
      equal(existingGroups, transformedGroups) &&
      (Object.keys(transformedGroups).length <= 1 ||
        existingConditionalType === filterGroupConditionalType)
    )
      return;

    patchState(this.state, {
      filterGroups: transformedGroups,
      filterGroupConditionalType
    });

    this.getUsers('NEW');
  }

  getUsers(type: 'NEW' | 'SCROLL', customPage?: number) {
    if (type === 'SCROLL' && !this.state.hasNext()) return;

    const payload = { ...this._buildDefaultPayload() };
    const page =
      type === 'NEW'
        ? (customPage ?? 0)
        : (customPage ?? this.state.page() + 1);
    this._contactsStore.getUsers({
      payload,
      size: 200,
      page,
      onSuccess: (response) => {
        const requestsByPage =
          type === 'NEW' ? {} : { ...this.state.userIdsByPage() };
        patchState(this.state, () => ({
          userIdsByPage: {
            ...requestsByPage,
            [page]: new Set(
              response.content.content.map(({ userId }) => userId)
            )
          },
          page,
          totalPages: response.content.totalPages,
          totalElements: response.content.totalElements,
          hasNext: !response.content.last
        }));
      }
    });
  }

  removeUser(id: string) {
    const userIdsByPage = this.state().userIdsByPage;
    if (!userIdsByPage) return;

    Object.entries(userIdsByPage).forEach(([pageStr, userIds]) => {
      if (userIds.has(id)) {
        userIds.delete(id);
        patchState(this.state, {
          userIdsByPage: {
            ...userIdsByPage,
            [pageStr]: structuredClone(userIds)
          }
        });
      }
    });
  }

  exportContacts() {
    this._contactsStore.exportUserContacts({
      payload: this._buildDefaultPayload(),
      onSuccess: (url) => downloadFileUrl(url)
    });
  }

  reset() {
    patchState(this.state, defaultState);
  }

  private _buildDefaultPayload() {
    const {
      searchQuery,
      sortField,
      sortDirection,
      filterGroupConditionalType,
      filterGroups
    } = this.state();
    const defaultPayload: GetUsersPayload = {
      andOr: filterGroupConditionalType,
      groups: Object.values(filterGroups),
      eventId: undefined,
      userIdsToRemove: [],
      userIdsToAdd: [],
      defaultSelectAll: true,
      searchQuery: searchQuery,
      similarityThreshold: 1,
      sortField,
      sortDirection
    };

    return defaultPayload;
  }
}
