import { tapResponse } from '@ngrx/operators';
import { patchState } from '@ngrx/signals';
import { addEntities, setEntities } from '@ngrx/signals/entities';
import { rxMethod } from '@ngrx/signals/rxjs-interop';
import { mergeMap, pipe, tap } from 'rxjs';

import { downloadFile } from 'src/app/shared/functions/http.functions';
import { RxMethodCallback } from 'src/app/shared/models/rxMethod.models';

import { MeetingsService } from '../meetings.service';
import {
  ExportMeetingsPayload,
  ExportMeetingsResponse,
  GetReportingMeetingsPayload,
  MeetingDetails,
  ReportingMeetingsResponse,
  UpdateOffPlatformMeetingPayload,
  UploadOffPlatformMeetingPayload
} from '../models/meetings.models';
import { MeetingsState, meetingsCollection } from './meetings.state';

export function uploadOffPlatform(
  meetingService: MeetingsService,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  state: any
) {
  return rxMethod<UploadOffPlatformMeetingPayload & RxMethodCallback<void>>(
    pipe(
      tap(() =>
        patchState<MeetingsState>(state, {
          uploadOffPlatformInflight: true
        })
      ),
      mergeMap(({ payload, onSuccess, onFail }) =>
        meetingService.uploadOffPlatform$(payload).pipe(
          tapResponse({
            next: () => {
              patchState<MeetingsState>(state, {
                uploadOffPlatformInflight: false
              });

              onSuccess?.();
            },
            error: () => {
              patchState<MeetingsState>(state, {
                uploadOffPlatformInflight: false
              });

              onFail?.();
            }
          })
        )
      )
    )
  );
}

export function fetchReportingMeetings(
  meetingService: MeetingsService,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  state: any
) {
  return rxMethod<
    GetReportingMeetingsPayload & RxMethodCallback<ReportingMeetingsResponse>
  >(
    pipe(
      tap(() =>
        patchState<MeetingsState>(state, {
          fetchReportingMeetingsInflight: true
        })
      ),
      mergeMap(({ payload, size, page, replaceState, onSuccess, onFail }) =>
        meetingService.getReportingMeetings$(size, page, payload).pipe(
          tapResponse({
            next: (response) => {
              patchState(
                state,
                replaceState
                  ? setEntities(response.content, meetingsCollection)
                  : addEntities(response.content, meetingsCollection)
              );

              patchState<MeetingsState>(state, {
                fetchReportingMeetingsInflight: false
              });

              onSuccess?.(response);
            },
            error: () => {
              patchState<MeetingsState>(state, {
                fetchReportingMeetingsInflight: false
              });

              onFail?.();
            }
          })
        )
      )
    )
  );
}

export function exportMeetings(
  meetingService: MeetingsService,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  state: any
) {
  return rxMethod<
    ExportMeetingsPayload & RxMethodCallback<ExportMeetingsResponse>
  >(
    pipe(
      tap(() =>
        patchState<MeetingsState>(state, { exportMeetingsInflight: true })
      ),
      mergeMap(({ payload, onSuccess, onFail }) =>
        meetingService.exportMeetings$(payload).pipe(
          tapResponse({
            next: (exportContactsResponse) => {
              patchState<MeetingsState>(state, {
                exportMeetingsInflight: false
              });
              downloadFile(exportContactsResponse, 'meetings');
              onSuccess?.(exportContactsResponse);
            },
            error: () => {
              patchState<MeetingsState>(state, {
                exportMeetingsInflight: false
              });
              onFail?.();
            }
          })
        )
      )
    )
  );
}

export function getOffPlatformMeetingDetails(
  meetingService: MeetingsService,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  state: any
) {
  return rxMethod<{ id: string } & RxMethodCallback<MeetingDetails>>(
    pipe(
      tap(() =>
        patchState<MeetingsState>(state, {
          getOffPlatformMeetingDetailsInflight: true
        })
      ),
      mergeMap(({ id, onSuccess, onFail }) =>
        meetingService.getOffPlatformMeetingDetails$(id).pipe(
          tapResponse({
            next: (meetingDetails) => {
              patchState<MeetingsState>(state, {
                getOffPlatformMeetingDetailsInflight: false
              });

              onSuccess?.(meetingDetails);
            },
            error: () => {
              patchState<MeetingsState>(state, {
                getOffPlatformMeetingDetailsInflight: false
              });

              onFail?.();
            }
          })
        )
      )
    )
  );
}

export function updateOffPlatformMeeting(
  meetingService: MeetingsService,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  state: any
) {
  return rxMethod<
    UpdateOffPlatformMeetingPayload & RxMethodCallback<MeetingDetails>
  >(
    pipe(
      tap(() =>
        patchState<MeetingsState>(state, {
          updateOffPlatformMeetingInflight: true
        })
      ),
      mergeMap(({ payload, onSuccess, onFail }) =>
        meetingService.updateOffPlatformMeeting$(payload).pipe(
          tapResponse({
            next: (meetingDetails) => {
              patchState<MeetingsState>(state, {
                updateOffPlatformMeetingInflight: false
              });

              onSuccess?.(meetingDetails);
            },
            error: () => {
              patchState<MeetingsState>(state, {
                updateOffPlatformMeetingInflight: false
              });

              onFail?.();
            }
          })
        )
      )
    )
  );
}

export function cancelOffPlatformMeeting(
  meetingService: MeetingsService,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  state: any
) {
  return rxMethod<{ id: string } & RxMethodCallback<void>>(
    pipe(
      tap(() =>
        patchState<MeetingsState>(state, {
          cancelOffPlatformMeetingInflight: true
        })
      ),
      mergeMap(({ id, onSuccess, onFail }) =>
        meetingService.cancelOffPlatformMeeting$(id).pipe(
          tapResponse({
            next: () => {
              patchState<MeetingsState>(state, {
                cancelOffPlatformMeetingInflight: false
              });

              onSuccess?.();
            },
            error: () => {
              patchState<MeetingsState>(state, {
                cancelOffPlatformMeetingInflight: false
              });

              onFail?.();
            }
          })
        )
      )
    )
  );
}
