import { computed, inject } from '@angular/core';

import {
  patchState,
  signalStore,
  withComputed,
  withMethods,
  withState
} from '@ngrx/signals';
import { v4 as uuid } from 'uuid';

import { decodeHtml } from 'src/app/shared/functions/html.functions';
import { withDevtools } from 'src/app/shared/functions/redux-dev-tools';
import { FileMetadata } from 'src/app/shared/models/file.models';
import {
  AudienceFilterGroup,
  ConditionalType
} from 'src/app/shared/services/crm/models/crm.models';
import {
  Campaign,
  UpsertCampaign
} from 'src/app/shared/services/email/email.models';
import { SelectedEventOverviewStore } from 'src/app/shared/services/events/state/events.state';

import {
  MenuItem,
  MergeTagCategory
} from '../../editor/axle-email-editor.component';

export interface EmailCampaignManagerContainerState {
  id: string | null;
  selectedCampaign: Campaign | null;
  name: string | null;
  bccSelf: boolean | null;
  groupOrganizations: boolean | null;
  body: string;
  fromEmail: string;
  subject: string;
  cc: string;
  bcc: string;
  displayName: string;
  attachments: FileMetadata[];
  overMaxFileSize: boolean;
  eventId: string | null;
  addedUserIds: Set<string>;
  removedUserIds: Set<string>;
  filterGroups: Record<string, AudienceFilterGroup>;
  filterGroupConditionalType: ConditionalType;
  defaultSelectAll: boolean;
}

const defaultState: EmailCampaignManagerContainerState = {
  id: null,
  name: null,
  bccSelf: null,
  groupOrganizations: null,
  body: '',
  fromEmail: '',
  subject: '',
  cc: '',
  bcc: '',
  displayName: '',
  attachments: [],
  overMaxFileSize: false,
  eventId: null,
  addedUserIds: new Set(),
  removedUserIds: new Set(),
  filterGroups: {},
  filterGroupConditionalType: 'AND',
  selectedCampaign: null,
  defaultSelectAll: false
};

export const EmailCampaignManagerContainerStore = signalStore(
  { providedIn: 'root' },
  withDevtools('email-campaign-manager'),
  withState<EmailCampaignManagerContainerState>(defaultState),
  withComputed((store) => {
    const overviewStore = inject(SelectedEventOverviewStore);
    return {
      mergeTags: computed(() => {
        const eventId = store.eventId();

        const mergeTags: MergeTagCategory[] = [
          {
            title: 'Recipient',
            menu: [
              {
                title: 'First Name',
                value: 'RECIPIENTS'
              },
              {
                title: 'Recipient Organization Name',
                value: 'RECIPIENT_ORG_NAME'
              }
            ]
          }
        ];

        if (eventId) {
          mergeTags.push({
            title: 'Event',
            menu: getEventMenuItems(!!overviewStore.singleMarketedOrg())
          });
        }

        return mergeTags;
      }),
      sendDisabled: computed(() => {
        const {
          bccSelf,
          groupOrganizations,
          displayName,
          body,
          subject,
          overMaxFileSize
        } = store;

        return (
          groupOrganizations() === null ||
          bccSelf() === null ||
          overMaxFileSize() ||
          !body() ||
          !displayName() ||
          !subject()
        );
      }),
      emptyAudience: computed(() => {
        const { filterGroups, addedUserIds, defaultSelectAll } = store;
        return (
          !Object.keys(filterGroups()).length &&
          !addedUserIds().size &&
          !defaultSelectAll()
        );
      }),
      campaignPayload: computed<UpsertCampaign>(() => {
        const {
          id,
          cc,
          bcc,
          bccSelf,
          groupOrganizations,
          filterGroupConditionalType,
          filterGroups,
          displayName,
          attachments,
          eventId,
          body,
          subject,
          addedUserIds,
          removedUserIds,
          name,
          fromEmail,
          defaultSelectAll
        } = store;

        const sanitizedBody = decodeHtml(body());
        const sanitizedSubject = decodeHtml(subject()).replace(/<\/?p>/g, '');
        // TODO: Host Table

        return {
          id: id(),
          email: {
            body: sanitizedBody,
            subject: sanitizedSubject,
            fromEmail: fromEmail(),
            cc: cc(),
            bcc: bcc(),
            attachments: attachments(),
            displayName: displayName(),
            bccSelf: !!bccSelf(),
            oneEmailPerUser: !groupOrganizations(),
            overrideDoNotEmailPrevention: true
          },
          audience: {
            filters: Object.values(filterGroups()),
            combinationOperator: filterGroupConditionalType(),
            excludeUserIds: Array.from(removedUserIds()),
            includeUserIds: Array.from(addedUserIds()),
            defaultSelectAll: defaultSelectAll()
          },
          frequency: null,
          eventId: eventId(),
          type: 'MANUAL',
          status: 'DRAFT',
          name: name() || '',
          recurring: null,
          startDateTime: null,
          endDateTime: null,
          numOccurences: null
        };
      })
    };
  }),
  withMethods((store) => ({
    updateState: (updates: Partial<EmailCampaignManagerContainerState>) =>
      patchState(store, {
        ...updates
      }),
    addRemoveUsers: (users: Set<string>) =>
      patchState<EmailCampaignManagerContainerState>(
        store,
        ({ removedUserIds, addedUserIds }) => ({
          removedUserIds: new Set([
            ...removedUserIds,
            ...Array.from(users).filter((userId) => !addedUserIds.has(userId))
          ]),
          addedUserIds: new Set(
            Array.from(addedUserIds).filter((userId) => !users.has(userId))
          )
        })
      ),
    popRemovedUser: (userId: string) =>
      patchState<EmailCampaignManagerContainerState>(
        store,
        ({ removedUserIds }) => {
          removedUserIds.delete(userId);
          return {
            removedUserIds: new Set(removedUserIds)
          };
        }
      ),
    addUser: (userId: string) =>
      patchState<EmailCampaignManagerContainerState>(
        store,
        ({ addedUserIds }) => {
          addedUserIds.add(userId);
          return {
            addedUserIds: new Set(addedUserIds)
          };
        }
      ),
    popAddedUser: (userId: string) =>
      patchState<EmailCampaignManagerContainerState>(
        store,
        ({ addedUserIds }) => {
          addedUserIds.delete(userId);
          return {
            addedUserIds: new Set(addedUserIds)
          };
        }
      ),
    selectCampaign: (selectedCampaign: Campaign) => {
      patchState(store, {
        selectedCampaign,
        cc: selectedCampaign.email.cc,
        bcc: selectedCampaign.email.bcc,
        fromEmail: selectedCampaign.email.fromEmail,
        displayName: selectedCampaign.email.displayName,
        groupOrganizations: !selectedCampaign.email.oneEmailPerUser,
        bccSelf: selectedCampaign.email.bccSelf,
        subject: selectedCampaign.email.subject,
        body: selectedCampaign.email.body,
        attachments: selectedCampaign.email.attachments || [],
        name: selectedCampaign.name,
        filterGroupConditionalType:
          selectedCampaign.audience?.combinationOperator || 'AND',
        filterGroups:
          selectedCampaign.audience?.filters.reduce(
            (acc, group) => ({
              ...acc,
              [uuid()]: group
            }),
            {}
          ) ?? {},
        defaultSelectAll: !!selectedCampaign.audience?.defaultSelectAll,
        addedUserIds: new Set(selectedCampaign.audience?.includeUserIds ?? []),
        removedUserIds: new Set(selectedCampaign.audience?.excludeUserIds ?? [])
      });
    },
    reset: (initialState?: Partial<EmailCampaignManagerContainerState>) =>
      patchState(store, { ...structuredClone(defaultState), ...initialState })
  }))
);

function getEventMenuItems(singleMarketedOrg: boolean) {
  const eventMenuItems: MenuItem[] = [
    {
      title: 'Event Name',
      value: 'EVENT_NAME'
    },
    {
      title: 'Coordinating Org Name',
      value: 'COORDINATING_ORG_NAME'
    },
    {
      title: 'Event Date Range',
      value: 'EVENT_DATE_RANGE'
    },
    {
      title: 'Event Request Deadline',
      value: 'EVENT_REQUEST_DEADLINE'
    },
    {
      title: 'Event Registration Deadline',
      value: 'EVENT_REGISTRATION_DEADLINE'
    },
    {
      title: 'Event Contact Email',
      value: 'EVENT_CONTACT_EMAIL'
    }
  ];

  if (singleMarketedOrg) {
    eventMenuItems.push({
      title: 'Host Organization Name',
      value: 'HOST_ORG_NAME'
    });
    eventMenuItems.push({
      title: 'Hosts',
      value: 'HOST_NAMES'
    });
  }

  return eventMenuItems;
}
