export function decodeHtml(html: string) {
  const txt = document.createElement('textarea');
  txt.innerHTML = html;
  return txt.value;
}

export function encodeHtml(html: string) {
  const txt = document.createElement('textarea');
  txt.textContent = html;
  return txt.innerHTML;
}
