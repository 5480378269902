import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';

import { AuthService } from '@auth0/auth0-angular';
import { filter, switchMap } from 'rxjs';

import { environment } from 'src/environments/environment';

import { jsonDefaultRequestOptions } from '../../contants/http.constants';
import { handleHttpResponse } from '../../functions/http.functions';
import { AuthenticationStore } from '../../state/authentication/authentication.state';
import {
  ExportMeetings,
  GetReportingMeetings,
  MeetingDetails,
  ReportingMeetingsResponse,
  UpdateOffPlatformMeeting,
  UploadOffPlatformMeeting
} from './models/meetings.models';

const baseMeetingUrl = `${environment.backendLink}/meetings`;
const uploadOffPlatformUrl = `${baseMeetingUrl}/uploadOffPlatform`;
const reportingUrl = `${baseMeetingUrl}/reporting`;
const exportMeetingsUrl = `${baseMeetingUrl}/export`;
const updateOffPlatformMeetingUrl = `${baseMeetingUrl}/offPlatform/update`;
const cancelOffPlatformMeetingUrl = `${baseMeetingUrl}/offPlatform/cancel`;

const getMeetingDetailsUrl = (meetingId: string) =>
  `${baseMeetingUrl}/offPlatform/${meetingId}`;

@Injectable({
  providedIn: 'root'
})
export class MeetingsService {
  private _auth0Service = inject(AuthService);
  private _httpClient = inject(HttpClient);
  private _authStore = inject(AuthenticationStore);

  uploadOffPlatform$(payload: UploadOffPlatformMeeting) {
    return this._auth0Service.isAuthenticated$.pipe(
      filter((authenticated) => authenticated),
      switchMap(() =>
        handleHttpResponse(
          this._httpClient.post(uploadOffPlatformUrl, payload, {
            ...jsonDefaultRequestOptions
          }),
          'uploadOffPlatform$',
          this._authStore.logout
        )
      )
    );
  }

  getReportingMeetings$(
    size: number,
    page: number,
    payload: GetReportingMeetings
  ) {
    return this._auth0Service.isAuthenticated$.pipe(
      filter((authenticated) => authenticated),
      switchMap(() => {
        return handleHttpResponse(
          this._httpClient.post<ReportingMeetingsResponse>(
            reportingUrl,
            JSON.stringify(payload),
            {
              ...jsonDefaultRequestOptions,
              params: { size, page }
            }
          ),
          'getReportingMeetings$',
          this._authStore.logout
        );
      })
    );
  }

  exportMeetings$(payload: ExportMeetings) {
    return this._auth0Service.isAuthenticated$.pipe(
      filter((authenticated) => authenticated),
      switchMap(() => {
        return handleHttpResponse(
          this._httpClient.post(exportMeetingsUrl, JSON.stringify(payload), {
            ...jsonDefaultRequestOptions,
            responseType: 'blob'
          }),
          'exportMeetings$',
          this._authStore.logout,
          30000
        );
      })
    );
  }

  getOffPlatformMeetingDetails$(id: string) {
    return this._auth0Service.isAuthenticated$.pipe(
      filter((authenticated) => authenticated),
      switchMap(() => {
        return handleHttpResponse(
          this._httpClient.get<MeetingDetails>(
            getMeetingDetailsUrl(id),
            jsonDefaultRequestOptions
          ),
          'getOffPlatformMeetingDetails$',
          this._authStore.logout
        );
      })
    );
  }

  updateOffPlatformMeeting$(payload: UpdateOffPlatformMeeting) {
    return this._auth0Service.isAuthenticated$.pipe(
      filter((authenticated) => authenticated),
      switchMap(() => {
        return handleHttpResponse(
          this._httpClient.patch<MeetingDetails>(
            updateOffPlatformMeetingUrl,
            payload,
            jsonDefaultRequestOptions
          ),
          'updateOffPlatformMeeting$',
          this._authStore.logout
        );
      })
    );
  }

  cancelOffPlatformMeeting$(meetingId: string) {
    return this._auth0Service.isAuthenticated$.pipe(
      filter((authenticated) => authenticated),
      switchMap(() => {
        return handleHttpResponse(
          this._httpClient.post(
            cancelOffPlatformMeetingUrl,
            { meetingId },
            jsonDefaultRequestOptions
          ),
          'cancelOffPlatformMeeting$',
          this._authStore.logout
        );
      })
    );
  }
}
