import { DOCUMENT } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
  Renderer2,
  effect,
  inject
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import {
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationSkipped,
  NavigationStart,
  Router,
  RouterOutlet
} from '@angular/router';

import { EditorModule } from '@tinymce/tinymce-angular';
import posthog from 'posthog-js';
import { debounceTime, map } from 'rxjs';

import { AxleToastService } from './shared/services/toast/axle-toast.service';
import { FeatureFlag } from './shared/services/user/user.models';
import { UserStore } from './shared/services/user/user.state';
import { ThemeStore } from './shared/state/theme/theme.state';

@Component({
  selector: 'axle-root',
  imports: [RouterOutlet, EditorModule],
  templateUrl: './app.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppComponent implements OnInit, OnDestroy {
  toastService = inject(AxleToastService);
  private _themeStore = inject(ThemeStore);
  private _renderer = inject(Renderer2);
  private _userStore = inject(UserStore);
  private _router = inject(Router);
  private _document: Document = inject(DOCUMENT);

  currentTheme = this._themeStore.theme;
  maintenanceMode = this._userStore.maintenanceModeEnabled;

  showProgressBar = toSignal(
    this._router.events.pipe(
      debounceTime(300),
      map((event) => {
        if (event instanceof NavigationStart) {
          return true;
        } else if (
          event instanceof NavigationEnd ||
          event instanceof NavigationCancel ||
          event instanceof NavigationError ||
          event instanceof NavigationSkipped
        ) {
          return false;
        } else {
          return true;
        }
      })
    )
  );

  constructor() {
    effect(() => {
      if (this.maintenanceMode()) {
        this.applyMaintenanceBackgroundClasses();
      } else {
        this.removeMaintenanceBackgroundClasses();
        this.removeBackgroundClasses();
        this._renderer.addClass(
          this._document.body,
          this.currentTheme().bgLight
        );
      }
    });
  }

  ngOnInit() {
    posthog.onFeatureFlags((flags) => {
      this._userStore.storeFeatureFlags(new Set(flags as FeatureFlag[]));
    });

    document.addEventListener('visibilitychange', this.handleVisibilityChange);
  }

  ngOnDestroy() {
    document.removeEventListener(
      'visibilitychange',
      this.handleVisibilityChange
    );
  }

  applyBackgroundClasses() {
    const classes =
      'animate-bgGrad bg-400% bg-gradient-to-r from-primary-300 via-green-300 to-organge-300';
    const classArray = classes.split(' ');

    classArray.forEach((className) =>
      this._renderer.addClass(this._document.body, className)
    );
  }

  applyMaintenanceBackgroundClasses() {
    const classes =
      'animate-bgGrad bg-400% bg-gradient-to-r from-orange-300 via-amber-300 to-yellow-300';
    const classArray = classes.split(' ');

    classArray.forEach((className) =>
      this._renderer.addClass(this._document.body, className)
    );
  }

  removeMaintenanceBackgroundClasses() {
    const classes =
      'animate-bgGrad bg-400% bg-gradient-to-r from-orange-300 via-amber-300 to-yellow-300';
    const classArray = classes.split(' ');

    classArray.forEach((className) =>
      this._renderer.removeClass(this._document.body, className)
    );
  }

  removeBackgroundClasses() {
    const classes =
      'animate-bgGrad bg-400% bg-gradient-to-r from-primary-300 via-green-300 to-organge-300';
    const classArray = classes.split(' ');

    classArray.forEach((className) =>
      this._renderer.removeClass(this._document.body, className)
    );
  }

  removeToast(index: number) {
    this.toastService.remove(index);
  }

  private handleVisibilityChange = () => {
    if (document.visibilityState === 'visible') {
      posthog.reloadFeatureFlags();
    }
  };
}
