import equal from 'fast-deep-equal/es6';

import {
  CampaignAudience,
  UpsertCampaign
} from 'src/app/shared/services/email/email.models';

export const defaultCampaignAudience: CampaignAudience = {
  filters: [],
  combinationOperator: 'AND',
  excludeUserIds: [],
  includeUserIds: [],
  defaultSelectAll: false
};

export function isDefault(campaign: UpsertCampaign): boolean {
  const {
    email: { body, subject, cc, bcc, attachments },
    name,
    audience
  } = campaign;

  return (
    equal(audience, defaultCampaignAudience) &&
    !name &&
    !body &&
    !subject &&
    !cc &&
    !bcc &&
    !attachments?.length
  );
}
