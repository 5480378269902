import { Injectable, computed, inject } from '@angular/core';

import { patchState, signalState } from '@ngrx/signals';

import { defaultPageSize } from 'src/app/shared/contants/table.constants';

import { CampaignDraft } from '../email.models';
import { EmailStore } from '../email.state';

interface CampaignDraftsPaginatorState {
  page: number;
  totalPages: number | null;
  totalDrafts: number | null;
  draftIds: string[] | null;
  search: string;
}

const defaultState: CampaignDraftsPaginatorState = {
  page: 0,
  totalPages: null,
  totalDrafts: null,
  draftIds: null,
  search: ''
};

@Injectable({
  providedIn: 'root'
})
export class AxleCampaignDraftsPaginatorService {
  private _emailStore = inject(EmailStore);

  paginatorState = signalState<CampaignDraftsPaginatorState>(defaultState);

  footer = computed(
    () =>
      `Showing <strong>1 - ${this.paginatorState.draftIds()?.length}</strong> of <strong>${this.paginatorState.totalDrafts() || 0}</strong> drafts`
  );

  filtersApplied = this.paginatorState.search;

  updateSearch(search: string, eventId: string | null) {
    patchState(this.paginatorState, {
      search
    });
    this.getDrafts('NEW', eventId);
  }

  getDrafts(type: 'NEW' | 'MORE', eventId: string | null) {
    if (type === 'NEW') {
      patchState(this.paginatorState, {
        page: 0,
        totalPages: null,
        totalDrafts: null
      });
    }

    const { page, totalPages, search } = this.paginatorState();
    if (totalPages && page >= totalPages) return;

    this._emailStore.getCampaignDrafts({
      page,
      size: defaultPageSize,
      payload: {
        eventId,
        search
      },
      onSuccess: (response) => {
        patchState(this.paginatorState, {
          page: page + 1,
          totalPages: response.totalPages ?? null,
          totalDrafts: response.totalElements ?? null
        });
        if (type === 'NEW') {
          this._resetIds(response.content);
        } else {
          this._addIds(response.content);
        }
      }
    });
  }

  reset() {
    patchState(this.paginatorState, defaultState);
  }

  _addIds(drafts: CampaignDraft[]) {
    const draftIds = drafts?.map((draft) => draft.id);
    patchState(this.paginatorState, (state) => ({
      draftIds: [...(state.draftIds ?? []), ...draftIds]
    }));
  }

  removeIds(ids: string[]) {
    patchState(this.paginatorState, ({ draftIds, totalDrafts }) => ({
      draftIds: draftIds?.filter((id) => !ids.includes(id)),
      totalDrafts: totalDrafts ? totalDrafts - ids.length : totalDrafts
    }));
  }

  _resetIds(drafts: CampaignDraft[]) {
    const draftIds = drafts?.map((draft) => draft.id);
    patchState(this.paginatorState, {
      draftIds
    });
  }
}
