import { Injectable, computed, inject } from '@angular/core';

import { patchState, signalState } from '@ngrx/signals';

import { defaultPageSize } from 'src/app/shared/contants/table.constants';

import { SentCampaign } from '../email.models';
import { EmailStore } from '../email.state';

interface SentCampaignsPaginatorState {
  page: number;
  totalPages: number | null;
  totalElements: number | null;
  campaignIds: string[] | null;
  search: string;
}

const defaultState: SentCampaignsPaginatorState = {
  page: 0,
  totalPages: null,
  totalElements: null,
  campaignIds: null,
  search: ''
};

@Injectable({
  providedIn: 'root'
})
export class AxleSentCampaignsPaginatorService {
  private _emailStore = inject(EmailStore);

  paginatorState = signalState<SentCampaignsPaginatorState>(defaultState);

  footer = computed(
    () =>
      `Showing <strong>1 - ${this.paginatorState.campaignIds()?.length}</strong> of <strong>${this.paginatorState.totalElements() || 0}</strong> campaigns`
  );

  filtersApplied = this.paginatorState.search;

  updateSearch(search: string, eventId: string | null) {
    patchState(this.paginatorState, {
      search
    });

    this.getCampaigns('NEW', eventId);
  }

  getCampaigns(type: 'NEW' | 'MORE', eventId: string | null) {
    if (type === 'NEW') {
      patchState(this.paginatorState, {
        page: 0,
        totalPages: null
      });
    }

    const { page, totalPages, search } = this.paginatorState();
    if (totalPages && page >= totalPages) return;

    this._emailStore.getSentCampaigns({
      page,
      size: defaultPageSize,
      payload: {
        search,
        eventId
      },
      onSuccess: (response) => {
        patchState(this.paginatorState, {
          page: page + 1,
          totalPages: response.emailCampaigns.totalPages ?? 0,
          totalElements: response.emailCampaigns.totalElements
        });
        if (type === 'NEW') {
          this._resetIds(response.emailCampaigns.content);
        } else {
          this._addIds(response.emailCampaigns.content);
        }
      }
    });
  }

  reset() {
    patchState(this.paginatorState, defaultState);
  }

  _addIds(campaigns: SentCampaign[]) {
    const campaignIds = campaigns?.map((campaign) => campaign.campaignId);
    patchState(this.paginatorState, {
      campaignIds: [
        ...(this.paginatorState().campaignIds ?? []),
        ...campaignIds
      ]
    });
  }

  _resetIds(campaigns: SentCampaign[]) {
    const campaignIds = campaigns?.map((campaign) => campaign.campaignId);
    patchState(this.paginatorState, {
      campaignIds
    });
  }
}
